import { IPoll } from "../app/models";
import { INewPollFormValues } from "../app/models/NewPollForm.model";

export const translatePollChoicesToText = (poll: IPoll) =>
  poll.choices
    .map(choice => `${choice.id}:${choice.name}:${choice.description}`)
    .join("\n");

export const translateNewPollChoicesToText = (poll: INewPollFormValues) =>
  poll.choices.map(choice => `${choice.name}:${choice.description}`).join("\n");
