import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const deletePollModalStyles = makeStyles((theme: ITheme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    margin: {
      margin: theme.spacing(1)
    },
    actions: {
      justifyContent: "flex-end",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main
    },
    title: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main
    },
    content: {
      padding: "24px",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default
    },
    divider: {
      backgroundColor: theme.palette.secondary.main
    },
    textField: {
      width: "100%"
    },
    deleteButton: {
      color: theme.palette.red
    }
  })
);
