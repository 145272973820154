import { colors } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

export interface IPaletteOptions extends PaletteOptions {
  icon: string;
}

const white = "#ffffff";
const black = "#000a12";
const dark = "#201507";
const primary = {
  main: "#2f2e33",
  light: "#58575b",
  dark: "#202023"
};
const secondary = {
  main: "#ffa726",
  light: "#ffb851",
  dark: "#b2741a"
};
/* const secondary = {
  main: "#3A5199",
  light: "#6173ad",
  dark: "#28386b"
}; */
// const secondary = {
//   main: "#512da8",
//   light: "#8559da",
//   dark: "#140078"
// };

export default {
  black,
  white,
  red: "#d50000",
  primary: {
    contrastText: white,
    dark: black,
    main: primary.main,
    light: primary.light
  },
  secondary: {
    contrastText: dark,
    dark: secondary.dark,
    main: secondary.main,
    light: secondary.light
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: white,
    secondary: colors.grey[400],
    link: colors.blue[600]
  },
  background: {
    default: "#777777",
    paper: white,
    hover: "rgba(255, 255, 255, 0.08)"
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
} as IPaletteOptions;
