import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const topbarStyles = makeStyles((theme: ITheme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.appBar + 100
    }
  })
);
