import { CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Auth } from "../../App";
import { db } from "../../firebase/firebase";
import { COLLECTION } from "../../firebase/firestore/collections";
import StorePolls from "../../firebase/firestore/StorePolls";
import { IPoll } from "../../models";
import { mainViewStyles } from "./MainView.styles";
import poweredBySipgate from "../../assets/images/powered_by_sipgate.png";
import { Topbar } from "../../components/Topbar/Topbar";
import { Poll } from "../../components/Poll/Poll";
import { AddPollModal } from "../../components/Modals/AddPollModal";

const MainView: React.FC = () => {
  const { user } = Auth.useContainer();
  const [polls, setPolls] = useState<IPoll[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const classes = mainViewStyles();

  useEffect(() => {
    const load = async () => setPolls([...(await StorePolls.getAllActive())]);
    load();
    db.collection(COLLECTION.POLLS).onSnapshot(async (docs) => {
      const apiDocs = docs.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      }) as IPoll[];
      setPolls(apiDocs);
    });
  }, []);

  if (!polls || !user) {
    return <CircularProgress />;
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const orderedPolls = polls.sort(
    (a, b) => b.endsAt.toMillis() - a.endsAt.toMillis()
  );

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        className={classes.container}
        spacing={10}
      >
        <Topbar onClick={handleOpenModal} />

        <Grid item className={classes.bumper} xs={12}></Grid>
        {orderedPolls.map((poll) => (
          <Poll key={poll.id} poll={poll} />
        ))}
        <AddPollModal open={openModal} onClose={handleCloseModal} />
      </Grid>
      <Grid
        container
        direction="column"
        justify="flex-end"
        alignItems="flex-end"
        spacing={3}
      >
        <Grid item xs={12}>
          <img
            src={poweredBySipgate}
            alt="powered by sipgate"
            style={{ width: "150px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MainView;
