import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { createContainer } from "unstated-next";
import AuthMiddleware from "./components/AuthMiddleware/AuthMiddleware";
import useUser from "./hooks/useUser";
import theme from "./theme";

export const Auth = createContainer(useUser);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Auth.Provider>
        <AuthMiddleware />
      </Auth.Provider>
    </ThemeProvider>
  );
}

export default App;
