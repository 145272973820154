import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const counterStyles = makeStyles((theme: ITheme) =>
  createStyles({
    progressContainer: {
      padding: "16px 0 0px",
      margin: "0 -48px -38px"
    },
    progressMain: {
      backgroundColor: theme.palette.background.default
    },
    label: {
      paddingLeft: "48px"
    }
  })
);
