import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const mainViewStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      padding: "16px"
    },
    bumper: {
      marginTop: "20px"
    }
  })
);
