import firebase, { firestore } from "firebase";
import { IApiDoc, IPoll, IPollTemplate } from "../../models/index";
import { db } from "../firebase";
import { COLLECTION } from "./collections";

const user = firebase.auth().currentUser;

const fromApiPolls = (doc: IApiDoc): IPoll => {
  return {
    id: doc.id,
    name: doc.name,
    description: doc.description,
    choices: doc.choices,
    active: doc.active,
    endsAt: doc.endsAt,
    updatedAt: doc.updatedAt,
    createdAt: doc.createdAt,
    userId: user ? user.uid : ""
  };
};

export const StorePolls = {
  getAll: async () => {
    const polls = (await db.collection(COLLECTION.POLLS).get()).docs.map(
      doc => {
        return { id: doc.id, ...doc.data() };
      }
    ) as IApiDoc[];
    return polls.map(poll => fromApiPolls(poll));
  },
  getAllActive: async () => {
    const polls = (
      await db
        .collection(COLLECTION.POLLS)
        .where("endsAt", ">", firestore.Timestamp.now())
        .get()
    ).docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    }) as IApiDoc[];
    return polls.map(poll => fromApiPolls(poll));
  },
  get: async (pollId: string) => {
    const doc = (
      await db
        .collection(COLLECTION.POLLS)
        .doc(pollId)
        .get()
    ).data() as IApiDoc;

    return fromApiPolls(doc);
  },
  delete: async (pollId: string) => {
    return await db
      .collection(COLLECTION.POLLS)
      .doc(pollId)
      .delete();
  },
  update: async (poll: IPoll) => {
    await db
      .collection(COLLECTION.POLLS)
      .doc(poll.id)
      .update({
        ...poll,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
  },
  add: async (newPoll: IPollTemplate) => {
    await db.collection(COLLECTION.POLLS).add({
      ...newPoll,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      active: true
    });
  }
};

export default StorePolls;
