import { Container } from "@material-ui/core";
import React from "react";
import { Auth } from "../../App";
import LoginView from "../../views/Login/LoginView";
import MainView from "../../views/Main/MainView";

const AuthMiddleware = () => {
  const { user } = Auth.useContainer();
  return (
    <Container maxWidth="lg">{user ? <MainView /> : <LoginView />}</Container>
  );
};

export default AuthMiddleware;
