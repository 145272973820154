import {
  AppBar,
  Card,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import moment from "moment";
import React, { useState } from "react";
import useUser from "../../hooks/useUser";
import { IPoll } from "../../models";
import { Counter } from "../Counter/Counter";
import PollChoices from "../PollChoices/PollChoices";
import { pollStyles } from "./Poll.styles";
import EditPoll from "./EditPoll/EditPoll";
import { DeletePollModal } from "../Modals/DeletePollModal";
import TabPanel from "../../views/Main/TabPanel";

interface IProps {
  poll: IPoll;
}

const a11yProps = (index: number) => {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
};

export const Poll: React.FC<IProps> = ({ poll }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const classes = pollStyles();
  const { user } = useUser();

  if (!user) {
    return null;
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const handleDeleteClick = () => {
    setTab(tab);
    setOpenDeleteModal(true);
  };

  return (
    <Grid item key={poll.id} xs={12}>
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Card elevation={3} className={classes.poll}>
          <div className={classes.cardMedia}>
            {user.uid === poll.userId && (
              <>
                <AppBar position="static" color="transparent">
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    scrollButtons="off"
                    indicatorColor="primary"
                    textColor="secondary"
                    aria-label="scrollable force tabs"
                    centered={true}
                  >
                    <Tab icon={<ListIcon />} {...a11yProps(0)} />
                    <Tab icon={<EditIcon />} {...a11yProps(1)} />
                    <Tab
                      icon={<DeleteIcon />}
                      {...a11yProps(0)}
                      className={classes.deleteTab}
                      onClick={handleDeleteClick}
                    />
                  </Tabs>
                </AppBar>
                <Divider style={{ width: "100%" }} light />
              </>
            )}
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.pollInfo}
            >
              <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="h3" component="h2">
                  {poll.name}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="body1" component="span">
                  {poll.description}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.counter}>
                <Counter time={moment(poll.endsAt.toDate()).unix()} />
              </Grid>
            </Grid>
          </div>
          <CardContent>
            <Grid container direction="column" justify="flex-start">
              <TabPanel value={tab} index={0}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.gridContainer}
                ></Grid>
                <PollChoices poll={poll} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <EditPoll poll={poll} onSave={() => setTab(0)} />
              </TabPanel>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <DeletePollModal
        open={openDeleteModal}
        poll={poll}
        onClose={() => setOpenDeleteModal(false)}
        safety={true}
      />
    </Grid>
  );
};
