import MomentUtils from "@date-io/moment";
import {
  Button,
  Grid,
  IconButton,
  Input,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import firebase from "firebase";
import moment from "moment";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import StorePolls from "../../../firebase/firestore/StorePolls";
import { IChoice, IPoll } from "../../../models/Poll.model";
import { editPollStyles } from "./EditPoll.styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { translatePollChoicesToText } from "../../../../utils/fast-edit-choices-utils";
import { hasEnded } from "../../../../utils/time-util";

interface IProps {
  poll: IPoll;
  onSave?: () => void;
}

const EditPoll: React.FC<IProps> = ({ poll, onSave: resetTabView }) => {
  const classes = editPollStyles();
  const [fastEdit, setFastEdit] = useState<boolean>(false);
  const [fastEditValue, setFastEditValue] = useState<string>(
    translatePollChoicesToText(poll)
  );
  const [choicesData, setChoicesData] = useState<IChoice[]>(poll.choices);
  const [pollName, setPollName] = useState<string>(poll.name);
  const [pollDescription, setPollDescription] = useState<string>(
    poll.description
  );
  const [pollEndsAt, setPollEndsAt] = useState<firebase.firestore.Timestamp>(
    poll.endsAt
  );

  const handleSave = async () => {
    const changedPoll = {
      ...poll,
      name: pollName,
      description: pollDescription,
      choices: choicesData,
      endsAt: pollEndsAt
    };
    if (resetTabView) {
      resetTabView();
    }
    await StorePolls.update(changedPoll);
  };

  const handleChangeChoice = (changedChoice: IChoice) => ({
    currentTarget
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!changedChoice.id) {
      changedChoice.id = uuid();
    }

    const newValue = {
      ...changedChoice,
      [currentTarget.name]: currentTarget.value
    };

    const updatedValues = choicesData.map(value => {
      if (value.id === newValue.id) {
        return newValue;
      }
      return value;
    });

    setChoicesData([...updatedValues]);
  };

  const handleChange = ({
    currentTarget
  }: React.ChangeEvent<HTMLInputElement>) => {
    switch (currentTarget.name) {
      case "title":
        setPollName(currentTarget.value);
        break;
      case "description":
        setPollDescription(currentTarget.value);
        break;
    }
  };

  const handleChangeEndsAt = (date: MaterialUiPickersDate) => {
    if (date) {
      setPollEndsAt(firebase.firestore.Timestamp.fromDate(date.toDate()));
    }
  };

  const handleFastEdit = () => {
    setFastEdit(!fastEdit);
  };

  const handleFastEditChange = ({
    currentTarget
  }: React.ChangeEvent<HTMLInputElement>) => {
    setChoicesData(
      fastEditValue.split("\n").map(
        choice =>
          ({
            id: choice.split(":")[0] || uuid(),
            name: choice.split(":")[1],
            description: choice.split(":")[2],
            votes: []
          } as IChoice)
      )
    );
  };

  const handleDeleteChoice = (choice: IChoice) => async () => {
    await StorePolls.update({
      ...poll,
      choices: poll.choices.filter(item => item.id !== choice.id)
    });
    const updatedPoll = ((await StorePolls.get(poll.id)) as unknown) as IPoll;
    setChoicesData(updatedPoll.choices);
  };

  const handleAdd = () => {
    const newId = uuid();
    setChoicesData([
      ...choicesData,
      { id: newId, name: "Name", description: "Description", votes: [] }
    ]);
    setFastEditValue([fastEditValue, `${newId}:Name:Description`].join("\n"));
  };

  return (
    <>
      <Grid container alignContent="space-around" direction="row" spacing={3}>
        <Grid item xs={6} className={classes.choiceContainer}>
          <TextField
            name="title"
            label="Title"
            variant="outlined"
            value={poll.name}
            className={classes.textField}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} className={classes.choiceContainer}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale="de"
          >
            <DateTimePicker
              showTodayButton={true}
              name="endsAt"
              inputVariant="outlined"
              label="Ends at"
              value={poll.endsAt.toDate()}
              disablePast={true}
              onChange={handleChangeEndsAt}
              style={{ width: "100%" }}
              ampm={false}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} className={classes.choiceContainer}>
          <TextField
            name="description"
            label="Description"
            variant="outlined"
            value={poll.description}
            className={classes.textField}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.choiceContainer}>
          <Typography variant="subtitle2" component="h3">
            Choices
          </Typography>
        </Grid>

        {fastEdit ? (
          <Grid item xs={12} className={classes.choiceContainer}>
            <Input
              name="choices"
              placeholder="Name:Description"
              value={fastEditValue}
              onChange={handleFastEditChange}
              rows={10}
              color="primary"
              multiline
              style={{ width: "100%" }}
            />
          </Grid>
        ) : (
          choicesData.map((choice, index) => (
            <Grid key={index} item xs={12} className={classes.choiceContainer}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                direction="row"
                spacing={1}
              >
                <TextField
                  name="id"
                  label="Id"
                  variant="outlined"
                  value={choice.id}
                  className={classes.hiddenTextField}
                />
                <Grid item xs={3}>
                  <TextField
                    name="name"
                    label="Name"
                    variant="outlined"
                    value={choice.name}
                    className={classes.textField}
                    onChange={handleChangeChoice(choice)}
                  />
                </Grid>
                <Grid item xs={hasEnded(poll.endsAt.toDate()) ? 9 : 8}>
                  <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    value={choice.description}
                    className={classes.textField}
                    onChange={handleChangeChoice(choice)}
                  />
                </Grid>
                {!hasEnded(poll.endsAt.toDate()) && (
                  <Grid item xs={1}>
                    <Tooltip title="Delete Choice" arrow>
                      <IconButton
                        aria-label="Delete Choice"
                        onClick={handleDeleteChoice(choice)}
                        className={classes.deleteButton}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
      <Tooltip title="Add Choice" arrow>
        <IconButton aria-label="Add Choice" onClick={handleAdd}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Quick Bulk Edit Choices" arrow>
        <IconButton aria-label="Quick Bulk Edit" onClick={handleFastEdit}>
          <ViewHeadlineIcon />
        </IconButton>
      </Tooltip>
      <Grid container justify="flex-end">
        <Button color="secondary" variant="contained" onClick={handleSave}>
          save
        </Button>
      </Grid>
    </>
  );
};

export default EditPoll;
