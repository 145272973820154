import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const tabPanelStyles = makeStyles((theme: ITheme) =>
  createStyles({
    gridContainer: {
      padding: "24px"
    }
  })
);
