import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const addPollModalStyles = makeStyles((theme: ITheme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    paper: {
      top: "50%",
      left: "50%",
      width: "700px",
      outline: "none",
      position: "absolute",
      maxWidth: "100%",
      transform: "translate(-50%, -50%)",
      boxShadow:
        "0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12)",
      maxHeight: "100%",
      overflowY: "auto"
    },
    cardActions: {
      justifyContent: "flex-end",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main
    },
    content: {
      display: "flex",
      justifyContent: "center"
    },
    cardHeader: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main
    },
    cardContent: {
      minHeight: "400px",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default
    },
    divider: {
      backgroundColor: theme.palette.secondary.main
    },
    editChoices: {
      width: "100%",
      border: "none",
      padding: "27px 12px 10px",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "-0.05px"
    },
    choiceContainer: {
      width: "100%"
    },
    textField: {
      width: "100%"
    },
    hiddenTextField: {
      display: "none"
    },
    choiceIcons: {
      color: theme.palette.white
    }
  })
);
