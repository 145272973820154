import { LinearProgress, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { counterStyles } from "./Counter.styles";

const initialNow = moment().unix();
const max = (time: number) => time - initialNow;

interface IProps {
  time: number;
}

export const Counter: React.FC<IProps> = ({ time }) => {
  const [now, setNow] = useState(initialNow);
  const [diff, setDiff] = useState(Math.max(0, time - now));
  const [endsAtText, setEndsAtText] = useState(
    moment.unix(now).to(moment.unix(time))
  );
  const classes = counterStyles();

  useEffect(() => {
    const interval = setInterval(() => {
      const newNow = moment().unix();
      setNow(newNow);
      setDiff(Math.max(0, time - newNow));
      setEndsAtText(moment.unix(newNow).to(moment.unix(time)));
    }, 1000);
    return () => clearInterval(interval);
  }, [time]);

  const countdown = () => {
    return (100 * diff) / max(time);
  };

  return (
    <div className={classes.progressContainer}>
      <LinearProgress
        variant="determinate"
        value={countdown()}
        color="secondary"
        className={classes.progressMain}
      />
      <Typography variant="body2" component="h6" className={classes.label}>
        {time > now ? `Ends: ${endsAtText}` : "Ended!"}
      </Typography>
    </div>
  );
};
