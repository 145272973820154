import { Box, Grid } from "@material-ui/core";
import React from "react";
import { tabPanelStyles } from "./TabPanel.styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = tabPanelStyles();

  return (
    <Grid
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.gridContainer}>
          {children}
        </Box>
      )}
    </Grid>
  );
};

export default TabPanel;
