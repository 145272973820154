import { lightBlue } from "@material-ui/core/colors";

export default {
  toolbar: {
    backgroundColor: lightBlue.A200
  },
  switchHeader: {
    backgroundColor: lightBlue.A200
    // color: "white",
  },
  day: {
    color: lightBlue.A700
  },
  daySelected: {
    backgroundColor: lightBlue["400"]
  },
  dayDisabled: {
    color: lightBlue["100"]
  },
  current: {
    color: lightBlue["900"]
  }
};
