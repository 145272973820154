import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const progressBarStyles = makeStyles((theme: ITheme) =>
  createStyles({
    progressBar: {
      position: "relative",
      width: "100%",
      borderRadius: "50px",
      border: `1px solid ${theme.palette.primary.light}`,
      marginBottom: "4px"
    },
    filler: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "8px",
      background: theme.palette.secondary.main,
      height: "100%",
      borderRadius: "inherit",
      transition: "width .2s ease-in",
      "&> span": {
        fontSize: "12px",
        fontWeight: "700"
      }
    },
    paddingRight: {
      paddingRight: "8px"
    },
    paddingLeft: {
      paddingLeft: "8px"
    }
  })
);
