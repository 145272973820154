import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const pollChoicesStyles = makeStyles((theme: ITheme) =>
  createStyles({
    choiceContainer: {
      width: "100%"
    },
    choiceButton: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(1),
      justifyContent: "flex-start",
      alignItems: "flex-start",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.08)"
      }
    },
    choiceSelected: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText
    },
    progressLabel: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 1,
      maxHeight: "75px", // borderlinearprogress root.height
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      "& span": {
        width: "100%"
      }
    }
  })
);
