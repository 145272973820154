import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../../theme";

export const editPollStyles = makeStyles((theme: ITheme) =>
  createStyles({
    paper: {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main
    },
    editChoices: {
      width: "100%",
      border: "none",
      padding: "27px 12px 10px",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "-0.05px"
    },
    choiceContainer: {
      width: "100%"
    },
    textField: {
      width: "100%"
    },
    hiddenTextField: {
      display: "none"
    },
    deleteButton: {
      color: theme.palette.red
    }
  })
);
