import { AppBar, Grid, Typography, Button, Toolbar } from "@material-ui/core";
import React from "react";
import { topbarStyles } from "./Topbar.styles";
import sipgateLogo from "../../assets/images/sipgate_logo.svg";

interface IProps {
  onClick: () => void;
}

export const Topbar: React.FC<IProps> = ({ onClick: handleOpenModal }) => {
  const classes = topbarStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="regular">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <img src={sipgateLogo} alt="sipgate" />

            <Typography variant="h6" noWrap>
              RetroLAN
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="default"
              onClick={handleOpenModal}
            >
              Add Poll
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
