import MomentUtils from "@date-io/moment";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Grid,
  IconButton,
  Input,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ListIcon from "@material-ui/icons/List";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import firebase, { firestore } from "firebase";
import moment from "moment";
import React, { useState } from "react";
import { translateNewPollChoicesToText } from "../../../utils/fast-edit-choices-utils";
import StorePolls from "../../firebase/firestore/StorePolls";
import useUser from "../../hooks/useUser";
import { IChoice, IChoiceTemplate, IPollTemplate } from "../../models";
import { INewPollFormValues } from "../../models/NewPollForm.model";
import { addPollModalStyles } from "./AddPollModal.styles";
moment.locale("de");

const INITIAL_POLL_VALUES = {
  name: "",
  description: "",
  endsAt: firestore.Timestamp.now(),
  choices: []
};

interface IProps {
  onClose: () => void;
  open: boolean;
}

export const AddPollModal: React.FC<IProps> = ({ onClose, open }) => {
  const [newPollValues, setNewPollValues] = useState<INewPollFormValues>(
    INITIAL_POLL_VALUES
  );
  const { user } = useUser();
  const classes = addPollModalStyles();
  const [fastEdit, setFastEdit] = useState<boolean>(false);

  if (!user) {
    return null;
  }

  const handleChangeChoice = (index: number) => ({
    currentTarget
  }: React.ChangeEvent<HTMLInputElement>) => {
    const updatedChoice = {
      ...newPollValues.choices[index],
      [currentTarget.name]: currentTarget.value
    } as IChoiceTemplate;

    const updatedValues = newPollValues.choices.map((item, itemIndex) => {
      if (itemIndex === index) {
        return updatedChoice;
      }
      return item;
    });

    setNewPollValues({
      ...newPollValues,
      choices: updatedValues
    });
  };

  const handleChange = ({
    currentTarget
  }: React.ChangeEvent<HTMLInputElement>) => {
    setNewPollValues({
      ...newPollValues,
      [currentTarget.name]: currentTarget.value
    });
  };

  const handleChangeEndsAt = (date: MaterialUiPickersDate) => {
    if (date) {
      setNewPollValues({
        ...newPollValues,
        endsAt: firebase.firestore.Timestamp.fromDate(date.toDate())
      });
    }
  };

  const handleFastEdit = () => {
    setFastEdit(!fastEdit);
  };

  const handleFastEditChange = ({
    currentTarget
  }: React.ChangeEvent<HTMLInputElement>) => {
    setNewPollValues({
      ...newPollValues,
      choices: currentTarget.value.split("\n").map(
        choice =>
          ({
            name: choice.split(":")[0],
            description: choice.split(":")[1]
          } as IChoiceTemplate)
      )
    });
  };

  const handleDeleteChoice = (index: Number) => async () => {
    setNewPollValues({
      ...newPollValues,
      choices: newPollValues.choices.filter(
        (item, itemIndex) => itemIndex !== index
      )
    });
  };

  const handleAddChoice = () => {
    setNewPollValues({
      ...newPollValues,
      choices: [
        ...newPollValues.choices,
        {
          name: "Name",
          description: "Description"
        }
      ]
    });
  };

  const addChoiceId = (choice: IChoiceTemplate, index: Number) =>
    ({
      id: String(index),
      name: choice.name,
      description: choice.description,
      votes: []
    } as IChoice);

  const handleAddPoll = async () => {
    const newPoll = {
      ...newPollValues,
      choices: newPollValues.choices.map((choice, index) =>
        addChoiceId(choice, index)
      ),
      userId: user.uid,
      active: true
    } as IPollTemplate;
    await StorePolls.add(newPoll);
    setNewPollValues(INITIAL_POLL_VALUES);
    onClose();
  };

  return (
    <Modal
      aria-labelledby="Add Poll Modal"
      aria-describedby="Add Poll Modal"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Card>
            <CardHeader title="Add Poll" className={classes.cardHeader} />
            <Divider className={classes.divider} />
            <CardContent className={classes.cardContent}>
              <div className={classes.content}>
                <Grid
                  container
                  alignContent="space-around"
                  direction="row"
                  spacing={3}
                >
                  <Grid item xs={6} className={classes.choiceContainer}>
                    <TextField
                      name="name"
                      label="Name"
                      variant="outlined"
                      value={newPollValues.name}
                      className={classes.textField}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.choiceContainer}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                      locale="de"
                    >
                      <DateTimePicker
                        showTodayButton={true}
                        name="endsAt"
                        inputVariant="outlined"
                        label="Ends at"
                        value={newPollValues.endsAt.toDate()}
                        disablePast={true}
                        onChange={handleChangeEndsAt}
                        style={{ width: "100%" }}
                        ampm={false}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} className={classes.choiceContainer}>
                    <TextField
                      name="description"
                      label="Description"
                      variant="outlined"
                      value={newPollValues.description}
                      className={classes.textField}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.choiceContainer}>
                    <Typography variant="subtitle2" component="h3">
                      Choices
                    </Typography>
                  </Grid>

                  {fastEdit ? (
                    <Grid item xs={12} className={classes.choiceContainer}>
                      <Input
                        name="choices"
                        placeholder="Name:Description"
                        value={translateNewPollChoicesToText(newPollValues)}
                        onChange={handleFastEditChange}
                        rows={10}
                        color="primary"
                        multiline
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  ) : (
                    newPollValues.choices.map((choice, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        className={classes.choiceContainer}
                      >
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                          direction="row"
                          spacing={1}
                        >
                          <Grid item xs={3}>
                            <TextField
                              name="name"
                              label="Name"
                              variant="outlined"
                              value={choice.name}
                              className={classes.textField}
                              onChange={handleChangeChoice(index)}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              name="description"
                              label="Description"
                              variant="outlined"
                              value={choice.description}
                              className={classes.textField}
                              onChange={handleChangeChoice(index)}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Tooltip title="Delete Choice" arrow>
                              <IconButton
                                aria-label="Delete Choice"
                                onClick={handleDeleteChoice(index)}
                                style={{ color: "red" }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  )}
                  <Tooltip title="Add Choice" arrow>
                    <IconButton
                      aria-label="Add Choice"
                      onClick={handleAddChoice}
                      className={classes.choiceIcons}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Quick Bulk Edit Choices" arrow>
                    <IconButton
                      aria-label="Quick Bulk Edit"
                      onClick={handleFastEdit}
                      className={classes.choiceIcons}
                    >
                      <ListIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </div>
            </CardContent>
            <Divider className={classes.divider} />
            <CardActions className={classes.cardActions}>
              <Button onClick={onClose}>Dismiss</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddPoll}
                disabled={
                  !newPollValues.choices.length ||
                  !newPollValues.name ||
                  !newPollValues.description
                }
              >
                Add
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Fade>
    </Modal>
  );
};
