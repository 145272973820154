import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";
import "firebase/storage";

const EMAIL_FOR_SIGN_IN_KEY = "emailForSignIn";
const { NODE_ENV } = process.env;
const isProduction = NODE_ENV === "production";

const firebaseConfig = {
  apiKey: "AIzaSyBb_HjWTUbhte9iRXpvChuT6xDL68AJ0OU",
  authDomain: "gamepoll-9e6dd.firebaseapp.com",
  databaseURL: "https://gamepoll-9e6dd.firebaseio.com",
  projectId: "gamepoll-9e6dd",
  storageBucket: "gamepoll-9e6dd.appspot.com",
  messagingSenderId: "596937593790",
  appId: "1:596937593790:web:f27748388e3a4a72e29fef",
};

const actionCodeSettings: firebase.auth.ActionCodeSettings = {
  url: isProduction
    ? "https://poll.sipgate.fun/emailSignIn"
    : "http://localhost:3000/emailSignIn",
  handleCodeInApp: true,
};

firebase.firestore.setLogLevel("debug");

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage().ref();

const googleProvider = new firebase.auth.GoogleAuthProvider();

export {
  db,
  storage,
  googleProvider,
  actionCodeSettings,
  EMAIL_FOR_SIGN_IN_KEY,
};
