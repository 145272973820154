import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
} from "@material-ui/core";
import firebase from "firebase";
import React, { useState, useEffect } from "react";
import loginImage from "../../assets/images/login.jpg";
import {
  googleProvider,
  actionCodeSettings,
  EMAIL_FOR_SIGN_IN_KEY,
} from "../../firebase/firebase";
import { Google as GoogleIcon } from "../../theme/icons";
import { loginViewStyles } from "./LoginView.styles";
import EmailIcon from "@material-ui/icons/Email";

const LoginView: React.FC = () => {
  const classes = loginViewStyles();
  const [email, setEmail] = useState("");
  const [emailFlow, setEmailFlow] = useState(false);

  useEffect(() => {
    const storedEmail = window.localStorage.getItem(EMAIL_FOR_SIGN_IN_KEY);
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const signInWithGoogle = async () =>
    await firebase.auth().signInWithRedirect(googleProvider);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
    window.localStorage.setItem(EMAIL_FOR_SIGN_IN_KEY, email);
    setEmailFlow(true);
  };

  const handleEmailChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(target.value);
  };

  const renderEmailSend = () => {
    return <Grid item>E-Mail was send. Please look in your inbox</Grid>;
  };

  return (
    <Grid
      container
      direction="row"
      alignContent="center"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Card elevation={3} className={classes.paper}>
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Login"
              height="380"
              image={loginImage}
              title="Contemplative Reptile"
              className={classes.cardMedia}
            />
          </CardActionArea>
          <CardContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              {!emailFlow ? (
                <>
                  <Grid item className={classes.formContainer}>
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <TextField
                            id="email"
                            label="E-Mail"
                            style={{ margin: 8 }}
                            placeholder="mail@example.com"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            onChange={handleEmailChange}
                            value={email}
                            className={classes.emailField}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            size="small"
                            variant="contained"
                            className={classes.loginButton}
                          >
                            <EmailIcon className={classes.socialIcon} />
                            Login with Link
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item>--- OR ---</Grid>
                  <Grid item>
                    <Button
                      onClick={signInWithGoogle}
                      size="small"
                      variant="contained"
                      className={classes.googleButton}
                    >
                      <GoogleIcon className={classes.socialIcon} />
                      Login with Google
                    </Button>
                  </Grid>
                </>
              ) : (
                renderEmailSend()
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginView;
