import {
  Button,
  ButtonBase,
  CardActions,
  Grid,
  Radio,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { Auth } from "../../App";
import StorePolls from "../../firebase/firestore/StorePolls";
import { IChoice, IPoll } from "../../models";
import { pollChoicesStyles } from "./PollChoices.styles";
import ProgressBar from "../ProgressBar/ProgressBar";
import { hasEnded } from "../../../utils/time-util";

interface IProps {
  poll: IPoll;
}

const PollChoices: React.FC<IProps> = ({ poll }) => {
  const { user } = Auth.useContainer();
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);
  const hasPollEnded = hasEnded(poll.endsAt.toDate());
  const orderedChoicesByName = poll.choices.sort((a, b) => {
    return a.name.toUpperCase() < b.name.toUpperCase()
      ? -1
      : a.name.toUpperCase() > b.name.toUpperCase()
      ? 1
      : 0;
  });

  const orderedChoicesByVotes = poll.choices.sort((a, b) => {
    return b.votes.length - a.votes.length;
  });

  if (!user) {
    return null;
  }

  const isSelected = (choiceId: string) => choiceId === selectedChoice;
  const isVoted = (poll: IPoll) =>
    poll.choices.some((choice: IChoice) => choice.votes.includes(user.uid));

  const classes = pollChoicesStyles();

  const getVotingResults = (choices: IChoice[], id: string) => {
    let sum = 0;
    choices.forEach((a: IChoice) => {
      sum += a.votes.length;
    });
    const currentChoice = choices.find(choice => choice.id === id);
    return currentChoice && sum ? (currentChoice.votes.length / sum) * 100 : 0;
  };

  const handleVoteChange = (choiceId: string | null = null) => () => {
    if (!choiceId || selectedChoice === choiceId) {
      setSelectedChoice(null);
    } else {
      setSelectedChoice(choiceId);
    }
  };

  const handleVote = (poll: IPoll) => async () => {
    const choices = poll.choices.map(choice => {
      if (choice.id === selectedChoice) {
        choice.votes.push(user.uid);
      }
      return choice;
    });
    await StorePolls.update({ ...poll, choices });
  };

  const handleRemoveVote = (poll: IPoll) => async () => {
    const choices = poll.choices.map(choice => {
      if (choice.votes.includes(user.uid)) {
        return {
          ...choice,
          votes: choice.votes.filter(vote => vote !== user.uid)
        };
      }
      return choice;
    });
    handleVoteChange(selectedChoice);
    await StorePolls.update({ ...poll, choices });
  };

  if (hasPollEnded || isVoted(poll)) {
    return (
      <>
        {orderedChoicesByVotes.map(choice => (
          <Grid item key={choice.id} className={classes.choiceContainer}>
            <ProgressBar
              label={choice.name}
              tooltip={`${choice.name}: ${choice.description}`}
              percentage={getVotingResults(poll.choices, choice.id)}
              count={choice.votes.length}
            />
          </Grid>
        ))}
        {!hasPollEnded && (
          <CardActions>
            <Grid container justify="flex-start">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleRemoveVote(poll)}
              >
                change vote
              </Button>
            </Grid>
          </CardActions>
        )}
      </>
    );
  }

  return (
    <>
      {orderedChoicesByName.map((choice, index) => (
        <Grid item key={index} className={classes.choiceContainer}>
          <ButtonBase
            className={classes.choiceButton}
            component="div"
            onClick={handleVoteChange(choice.id)}
          >
            <Grid container direction="column" justify="flex-start">
              <Typography variant="h5" component="h2">
                {choice.name}
              </Typography>
              <Typography variant="subtitle2" component="h3">
                {choice.description}
              </Typography>
            </Grid>
            <Grid container justify="flex-end">
              <Radio checked={isSelected(choice.id)} />
            </Grid>
          </ButtonBase>
        </Grid>
      ))}
      <CardActions>
        <Grid container justify="flex-end">
          <Button
            color="secondary"
            variant="contained"
            disabled={!selectedChoice}
            onClick={handleVote(poll)}
          >
            vote
          </Button>
        </Grid>
      </CardActions>
    </>
  );
};

export default PollChoices;
