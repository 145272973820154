import { progressBarStyles } from "./ProgressBar.styles";
import React from "react";
import { Tooltip } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";

interface IProps {
  percentage: number;
  label?: string;
  tooltip?: string;
  count?: number;
}

const ProgressBar: React.FC<IProps> = ({
  percentage,
  label,
  tooltip,
  count
}) => {
  const classes = progressBarStyles();
  return (
    <Tooltip TransitionComponent={Zoom} title={tooltip} arrow>
      <div className={classes.progressBar}>
        <Filler label={label} percentage={percentage} count={count} />
      </div>
    </Tooltip>
  );
};

const Filler: React.FC<IProps> = ({ percentage, label, count }) => {
  const classes = progressBarStyles();
  return (
    <div className={classes.filler} style={{ width: `${percentage}%` }}>
      <span className={classes.paddingRight}>{label}</span>
      <span className={classes.paddingLeft}>
        {Math.round(percentage)}% ({count})
      </span>
    </div>
  );
};

export default ProgressBar;
