import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const pollStyles = makeStyles((theme: ITheme) =>
  createStyles({
    poll: {
      width: 850,
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main
    },
    cardMedia: {
      objectPosition: "center",
      backgroundColor: theme.palette.primary.dark,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      alignContent: "center"
    },
    choiceContainer: {
      width: "100%"
    },
    choiceButton: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(1),
      justifyContent: "flex-start",
      alignItems: "flex-start",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.08)"
      }
    },
    choiceSelected: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText
    },
    progressLabel: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 1,
      maxHeight: "75px", // borderlinearprogress root.height
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      "& span": {
        width: "100%"
      }
    },
    gridContainer: {
      padding: "0"
    },
    pollInfo: {
      padding: "16px 48px"
    },
    gridItem: {
      width: "100%",
      color: theme.palette.white
    },
    counter: {
      width: "100%"
    },
    deleteTab: {
      color: theme.palette.red
    }
  })
);
