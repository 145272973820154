import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Divider
} from "@material-ui/core";
import React, { useState } from "react";
import StorePolls from "../../firebase/firestore/StorePolls";
import { IPoll } from "../../models";
import { deletePollModalStyles } from "./DeletePollModal.styles";

interface IProps {
  poll: IPoll;
  open: boolean;
  onClose: () => void;
  safety?: boolean;
}

export const DeletePollModal: React.FC<IProps> = ({
  poll,
  open,
  onClose,
  safety
}) => {
  const [safeCheck, setSafeCheck] = useState<string>("");
  const classes = deletePollModalStyles();
  const isDeleteOkay = (safety && safeCheck === poll.name) || !safety;

  const handleDelete = async () => {
    if (isDeleteOkay) {
      await StorePolls.delete(poll.id);
      onClose();
    }
  };

  const handleClose = () => {
    setSafeCheck("");
    onClose();
  };

  const handleChange = ({
    currentTarget
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSafeCheck(currentTarget.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title" className={classes.title}>
        Delete Poll
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent className={classes.content}>
        <DialogContentText color="textPrimary">
          If you delete a vote, all saved data will be lost: Voting details,
          voting options and user votes.
          <br />
          <br />
          {`Enter the name of the poll "${poll.name}" in the input field below.`}
        </DialogContentText>
        {safety && (
          <TextField
            autoFocus
            margin="dense"
            id="check"
            label="Enter poll name"
            fullWidth
            onChange={handleChange}
          />
        )}
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleDelete}
          color="secondary"
          disabled={!isDeleteOkay}
          className={classes.deleteButton}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
