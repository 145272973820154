import { auth } from "firebase";
import { useEffect, useState } from "react";
import firebase from "firebase";
import { EMAIL_FOR_SIGN_IN_KEY } from "../firebase/firebase";

const useUser = () => {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => auth().onAuthStateChanged(setUser), []);

  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    const email = window.localStorage.getItem(EMAIL_FOR_SIGN_IN_KEY);
    if (email) {
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // window.localStorage.removeItem("emailForSignIn");
        })
        .catch((error) => console.log(error));
    }
  }

  const logout = async () => await firebase.auth().signOut();

  return { user, logout };
};

export default useUser;
