import { makeStyles, createStyles } from "@material-ui/core";
import { ITheme } from "../../theme";

export const loginViewStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      height: "100%",
    },
    paper: {
      width: 450,
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
    cardMedia: {
      objectPosition: "top",
    },
    socialIcon: {
      marginRight: theme.spacing(1),
    },
    formContainer: {
      width: "100%",
    },
    form: {},
    emailField: {
      "& .MuiOutlinedInput-root": {
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
      },
    },
    loginButton: {
      color: theme.palette.white,
      backgroundColor: theme.palette.background.default,
      height: "53px",
      border: "none",
      outline: "none",
      cursor: "pointer",
      position: "relative",
      zIndex: 0,
      borderRadius: "4px",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      marginLeft: "8px",
    },
    googleButton: {
      color: theme.palette.white,
      backgroundColor: theme.palette.background.default,
      height: "30px",
      border: "none",
      outline: "none",
      cursor: "pointer",
      position: "relative",
      zIndex: 0,
      borderRadius: "10px",
      "&:before": {
        content: "",
        background:
          "linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000)",
        position: "absolute",
        top: "-2px",
        left: "-2px",
        backgroundSize: "400%",
        zIndex: "-1",
        filter: "blur(5px)",
        width: "calc(100% + 4px)",
        height: "calc(100% + 4px)",
        animation: "glowing 20s linear infinite",
        opacity: 0,
        transition: "opacity .3s ease-in-out",
        borderRadius: "10px",
      },
      "&:active": {
        color: theme.palette.primary.dark,
        "&:after": {
          color: theme.palette.primary.dark,
        },
      },
      "&:hover": {
        "&:before": {
          opacity: 1,
        },
      },
      "&:after": {
        zIndex: -1,
        content: "",
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "#111",
        left: 0,
        top: 0,
        borderRadius: "10px",
      },
    },
  })
);
